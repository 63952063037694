@font-face {
  font-family: 'Museo';
  src: url('./assets/fonts/Fonts-Museo/Fonts-Museo/Museo300-Regular.otf') format('truetype');
}
.MuiDialogTitle-root span {
  font-family: 'Museo';
}

body,
html {
  background-color: '#E5E5E5';
  font-family: 'Museo';
  margin: 0;
}

h2.remove-whitespaces {
  margin: 0;
  padding: 0;
  line-height: 22px;
}

h2 {
  font-weight: 500;
}

h5,
h6,
span {
  font-family: Arial;
}

button:focus-visible {
  outline-width: 2px;
  outline-style: solid;
  outline-color: #222160;
}

.SnackbarContainer-root div.MuiCollapse-root:nth-of-type(1n + 4) {
  display: none;
}
